/*
 * STMNT Framework
 * 2018-2019
 *
 */

(function ($) {
    'use strict';

    var lang = $('body').data('lang');


    var STMNT = {

        debug: false,

        init: function (debug = false) {

            this.debug = debug;
            this.initNav();
            this.initHeader();
            this.initTeaserScroll();
            this.initContactPerson();
            this.initSearch();
            this.initEventForm();
            this.scrollToTop(900);
            this.initMobileNav();
            console.log( '%cLoaded STMNT Theme successfully', 'background-color: #323A45; color: lightgreen; font-family: "Arial"; font-size: 14px;' );
        },

        initNav: function () {

            if (this.isElementorMode())
                return;


            var menu = $('.js--main-menu');
            var submenu = $('.js--sub-menu');
            var submenuContainer = $('.header-nav-sub');


            var close_menu = function () {
                submenu.stop(false, false).animate({'height': 0}, 500, function () {
                });
                submenu.find('li').removeClass('is-active');
                submenu.removeClass('is-open');
            };

            var open_menu = function (element) {

                var opend_item_ID = element.parent().attr('ID');
                var opend_item = submenu.find('.' + opend_item_ID);
                var opend_item_height = submenuContainer.attr('data-height') + 'px';

                submenu.stop(false, false).animate({'height': opend_item_height}, 500, function () {

                });
                opend_item.addClass('is-active').siblings().removeClass('is-active');

                submenu.addClass('is-open');

            };

            $(window).on('load resize',function(){

                submenu.find('>li').each(function (index, item) {
                    var _height = $(this).height();
                    var _old_height = submenuContainer.attr('data-height');

                    if (parseInt(_old_height) <= parseInt(_height))
                        submenuContainer.attr('data-height', _height);

                });

            });


            menu.on('click', 'a', function (e) {
                e.preventDefault();

                var SameLink = $(this).parent().hasClass('is-active');

                if (submenu.hasClass('is-open') && SameLink){
                    close_menu();
                    $(this).parent().removeClass('is-active');
                }else{
                    open_menu($(this));
                    $(this).parent().addClass('is-active').siblings().removeClass('is-active');
                }

                return false;
            });

           /* menu.on('mouseover', 'li a', function () {

                if (submenu.hasClass('is-open'))
                    open_menu($(this));

            });*/


            $('.main-content').on('click touch', function (e) {
                close_menu();
            });


        },

        initHeader: function () {

            $('.js-accordion').accordion({
                "transitionSpeed": 400
            });

            $('.js-slick-slider').slick({
                dots: false,
                infinite: true,
                speed: 800,
                slidesToShow: 1,
                centerMode: true,
                variableWidth: true,
                /*
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            dots: true,
                            centerMode: false,
                        }
                    }]
                */
            });

        },


        initTeaserScroll: function () {

            if($(window).width() <= '991'){


                $('.stmnt-widget--image-scroll-teaser .inner-scroll ul').slick({
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots:true,
                });

                return;
            }

            var scroll_container = $('.js--scroll-teaser');

            if (!scroll_container.length) {
                return;
            }
            $(window).on('resize load',function(e){
                var B_height = scroll_container.find('.mask-container .mask-before').height();
                var A_height = scroll_container.find('.mask-container .mask-after').height();
                scroll_container.find('.inner-scroll ul').css({
                    'margin-top':B_height+'px',
                    'margin-bottom':A_height+'px',
                })
            });



            $.fn.ScrollStart = function() {
                var elementTop = Math.round($(this).offset().top);
                var viewportTop = $(window).scrollTop();

                if(elementTop - viewportTop <= 1  ){
                    return true;
                }
                return false;
            };

            $.fn.ScrollEnd = function() {
                var elementBottom = Math.round($(this).offset().top + $(this).outerHeight(true));
                var viewportBottom= $(window).scrollTop() + $(window).height();

                if(elementBottom - viewportBottom <= 1   ){
                    return true;
                }
                return false;
            };

            $(window).on('resize scroll load',function(e){

              var scroll = $(window).scrollTop();


              var hasStarted = scroll_container.ScrollStart();
              var hasEnded = scroll_container.ScrollEnd();


              if(hasStarted  && !scroll_container.hasClass('status--start') ){

                   scroll_container.addClass('status--start');
              }

              if(!hasStarted) {
                  scroll_container.removeClass('status--start');
              }


              if(hasEnded  && !scroll_container.hasClass('status--end') ){

                    scroll_container.addClass('status--end');
              }


                if(!hasEnded) {
                    scroll_container.removeClass('status--end');
                }


            });



        },

        initSearch: function () {

            var Data = {'action': 'load_search_results','page':1};
            var Main = $('.js-search-widget');

            if (!Main.length)
                return;

            // Input from URL
            // input by Form
            // input by link



            // Input By URL + // Input By Paged

            $(window).on('load hashchange', function() {
                if (location.hash && location.hash.length) {

                    Data = {'action': 'load_search_results','page':1};

                    var hash = decodeURIComponent(location.hash.substr(1));

                    $.extend(Data, JSON.parse('{"' + hash.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                        return key === "" ? value : decodeURIComponent(value)
                    }));

                    Main.find('input').val(Data.s);

                    StartSearch(Data);
                }

            });




            // input By Form

            Main.find('form').on('submit', function (e) {

                e.preventDefault();
                var SearchValue = Main.find('input').val();

                Data.s = SearchValue;
                window.location.hash = 's='+SearchValue+'&page=1';

                StartSearch(Data);

                return false;
            });










            function StartSearch($Data){

                var Target = Main.find('.js--search-data');
                Target.addClass('ajax-loading');
                $([document.documentElement, document.body]).animate({ scrollTop: Target.offset().top - 100 }, 500);

                $.ajax({
                    type: 'post',
                    url: ajax.url,
                    data: Data,
                    dataType: 'html',
                    success: function (response) {


                        Target.html(response);


                    },
                    complete: function () {
                        Target.removeClass('ajax-loading');
                    }
                });

            }




        },

        initContactPerson: function (){
            var filter = $('.js-cp--filter');

            if(!filter.length)
                return false;


            filter.find('input[type="radio"]').on('change',function () {

                var value  = $(this).val();

                var item = $('.js-cp--list').find('[data-filter="'+value+'"]');
                var title = $('.js-cp--main-title');

                if(item.length){
                    item.fadeIn(500).siblings().fadeOut(500);
                    title.html(item.data('title'));
                }else{
                    $('.js-cp--list').find('[data-filter]').fadeIn(500);
                    title.html(title.data('title'));
                }

            });

            filter.find('input[type="radio"]:first').trigger('click');

        },


        initEventForm:function(){

            var Main = $('.stmnt-widget--events-form');

            Main.find('input[type="text"] , input[type="email"] , textarea ').on('focus',function(){
                 $(this).closest('.stmnt-input').find('.placeholder').addClass('is-valid');
                $(this).addClass('is-active');
            });


            Main.find('input[type="text"] , input[type="email"] , textarea ').on('blur',function(){
                if($(this).val() === ''){
                    $(this).closest('.stmnt-input').find('.placeholder').removeClass('is-valid');
                    $(this).removeClass('is-active');
                }

            });


        },


        isElementorMode: function () {
            $.urlParam = function (name) {
                var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
                if (results == null) {
                    return null;
                } else {
                    return true || false;
                }
            };
            return $.urlParam('elementor-preview');
        },

        

        scrollToTop: function(time) {
            var button_to_top = document.getElementById('button--to-top');
            $(button_to_top).on('click', function(event){
                event.preventDefault();
                var target = $('.main-header');
                $('html, body').stop().animate({
                    'scrollTop': target.offset().top
                }, time, 'swing');
            });
        },


        initMobileNav:function () {

          $('.js--mobile-nav').on('click touch',function () {
                $('html,body').toggleClass('is-mobile-menu-active');

          });


          $('.js--mobile-sub-menu li.menu-item-has-children>a').on('click touch',function (e) {

              e.preventDefault();

              var submenu = $(this).parent().find('>ul.sub-menu');

              if(submenu.length){
                  submenu.slideToggle('fast');
              }

              return;

          });


            $('.js--mobile-nav-close').on('click touch',function () {
                $('html,body').removeClass('is-mobile-menu-active');
            });



        },

    };


    STMNT.init(true);


}(jQuery));